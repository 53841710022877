import React from 'react';
import ReactDOM from 'react-dom/client';

// Styles
import './index.css';

// Components
import App from './App';

// Material UI Theme
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from "@mui/material"

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto Condensed',
      'sans-serif',
      '-apple-system',
    ].join(','),
  },
  palette: {
    mode: 'light',
    cardButton: {
      light: "#FCE3E7",
      main: "#FCE3E7",
      dark: "#cf102d",
      contrastText: "#A83B3B",
    },
    iconCardButton: {
      light: "#FCE3E7",
      main: "#A83B3B",
      dark: "#cf102d",
      contrastText: "#cf102d",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <App/>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>
);