import React from 'react';

// MUI
import { Button, styled } from '@mui/material';

const ButtonStyled = styled(Button)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr 2.0rem",

  marginBottom: "0.75rem",

  fontWeight: "bold",
  textAlign: "left",

  "& .MuiButton-endIcon": {
    textAlign: "center",
    "& .MuiSvgIcon-root": {
      width: "2.0rem",
      height: "2.0rem",
    },
  },

  "&:hover": {
    color: "#FFFFFF",
  },
}));

export default function CardButton({children, ...rest}) {
  return (
    <ButtonStyled color='cardButton' variant='contained' fullWidth size='large' target="_blank" rel="noreferrer" {...rest}>
      {children}
    </ButtonStyled>
  )
};