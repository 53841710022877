import React from 'react';

// Components
import Home from './components/Pages/Home/Home';

function App() {
  return (
    <React.Fragment>
      <Home/>
    </React.Fragment>
  );
}

export default App;
